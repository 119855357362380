import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import CsvDownloader from "react-csv-downloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LenderStatus from "../../components/status/LenderStatus";
import LenderStatusSelect from "../../components/formfields/LenderStatusSelect";
import LenderTitle from "../../components/fields/LenderTitle";
import Loader from "../../components/Loader";
import Product from "../../components/fields/Product";
import ProductSingular from "../../components/formfields/ProductSingular";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";

const ExclusivePricePointReport = () => {
  const history = useHistory();
  const defaultFiltrationState = {
    page: 1,
    perPage: 1000000,
    product: ["1"],
    status: ["active"],
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [lenders, setLenders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [logExport, setLogExport] = useState(null);
  const [allCheck, setAllCheck] = useState(true);

  useEffect(() => {
    setLoading(true);
    API.post("/admin/lender/list", filtration).then((response) => {
      let lendersList = response.data.data;
      lendersList.forEach((item) => {
        item['checked'] = true;
      })
      lendersList.length > 0 &&
      lendersList.forEach((item) => {
        if (item.checked) selectedLenders.push(item.id);
      });

      const sorted = Object.values(lendersList).sort(function (a, b) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      });

      setAllCheck(true)
      setLenders(sorted);
      setLogExport(null);
      setSelectedLenders(selectedLenders);
      setLoading(false);
    });
  }, [filtration]);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const generateCSV = () => {
    API.post("/admin/order/getprice", {
      lenders: selectedLenders,
      productId: filtration.product,
    }).then((response) => {
      setLogExport(response.data.data);
    });
  };

  const onCheckAll = () => {
    if (allCheck) {
      setAllCheck(false);
      lenders.forEach((lender) => {
        lender.checked = false;
      });
      setSelectedLenders([]);
    } else {
      let tempLenders = [];
      setAllCheck(true);
      lenders.forEach((lender) => {
        lender.checked = true;
        tempLenders.push(lender.id);
      });
      setSelectedLenders(tempLenders);
    }
  };

  const generateCSVFileName = () => {
    const productId = filtration.product && filtration.product[0];
    let fileName = "Exclusive Price Point Report - ";

    switch (productId) {
      case "1":
        fileName += "Windows";
        break;
      case "2":
        fileName += "Roofing";
        break;
      case "3":
        fileName += "Bath";
        break;
      case "4":
        fileName += "Siding";
        break;
      case "5":
        fileName += "Solar";
        break;
      case "6":
        fileName += "Refinance";
        break;
      case "6":
        fileName += "Debt Settlement";
        break;
    }

    return fileName;
  };

  const onCheck = (lender) => {
    if (lender.checked) {
      lender.checked = false;
      selectedLenders.length > 0 &&
        setSelectedLenders(
          selectedLenders.filter((item) => item !== lender.id)
        );
    } else {
      lender.checked = true;
      setSelectedLenders([...selectedLenders, lender.id]);
    }
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Exclusive Price Point Report</h1>
      <Row form className="mt-1">
        <Col md={3}>
          <ProductSingular
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={4}>
          <LenderStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={generateCSV}>
            Generate Report
          </Button>
        </Col>
        <div className="col-auto">
          {logExport && (
            <CsvDownloader datas={logExport} filename={generateCSVFileName()}>
              <FontAwesomeIcon
                className="align-middle cursor-pointer"
                icon={faFileCsv}
                style={{ fontSize: "20px" }}
                fixedWidth
              />
            </CsvDownloader>
          )}
        </div>
      </Row>
      <Row form className="mt-2">
        <Col>
          {loading && <Loader />}
          <Card>
            {!loading && lenders && lenders.length > 0 ? (
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>
                        Include - all check{" "}
                        {
                          <input
                            type="checkbox"
                            onChange={(e) => onCheckAll()}
                            checked={allCheck}
                          />
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lenders.map((lender, index) => {
                      return (
                        <tr key={index}>
                          <td>{lender.id}</td>
                          <td>
                            {
                              <LenderTitle
                                title={lender.title}
                                id={lender.id}
                              />
                            }
                          </td>
                          <td>
                            {lender.products.map((value) => (
                              <Product key={value.id} product={value} />
                            ))}
                          </td>
                          <td>{<LenderStatus status={lender.status} />}</td>
                          <td>
                            {
                              <input
                                type="checkbox"
                                onChange={(e) => onCheck(lender)}
                                checked={lender.checked}
                              />
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExclusivePricePointReport;
