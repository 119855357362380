import React,{useState,useEffect} from "react";
import {Badge, Breadcrumb, BreadcrumbItem, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {Link} from "react-router-dom";
import API from "../../utils/API";
import LeadPostLog from "./leadpage/LeadPostLog";
import LeadIOLog from "./leadpage/LeadIOLog";
import Log from "./leadpage/Log";
import PingDetailsPage from "./pingpage/PingDetailsPage";
import LeadDetailsPage from "./leadpage/LeadDetailsPage";

const Ping = (props) => {
    const [activeTab,setActiveTab] = useState("summary")
    const [update, setUpdate] = useState(true)

    const [ping,setPing] = useState(null)
    const [postingLog,setPostingLog] = useState(null)
    const [ioLog,setIoLog] = useState(null)

    useEffect(() => {
        API.get('/admin/ping/'+props.match.params.id).then((response) => {
            setPing(response.data.data)
        });
        API.get('/admin/ping/data/'+props.match.params.id).then((response) => {
            setIoLog(response.data.data)
        });
        API.get('/admin/ping/posting/'+props.match.params.id).then((response) => {
            setPostingLog(response.data.data)
        });
    }, [props.match.params.id,update])

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/lead/ping/report/details">Pings</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id}</BreadcrumbItem>
            </Breadcrumb>
            <div className={"tab default"}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "summary" })}
                            onClick={() => {
                                setActiveTab("summary");
                            }}
                        >
                            Summary
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "details" })}
                            onClick={() => {
                                setActiveTab("details");
                            }}
                        >
                            Lender post log {(postingLog && postingLog.length > 0) && <Badge color="primary">{postingLog.length}</Badge>}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "postlog" })}
                            onClick={() => {
                                setActiveTab("postlog");
                            }}
                        >
                            Request/Response
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="summary">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mt-md-1">
                                {ping &&
                                    <>
                                        <strong>Ping information</strong>
                                        <PingDetailsPage ping={ping}/>
                                    </>
                                }
                            </div>
                            <div className="col-sm-12 col-md-6 mt-md-1">
                                {(ping && ping.lead) &&
                                    <>
                                        <strong>Lead information</strong>
                                        <LeadDetailsPage lead={ping.lead}/>
                                    </>
                                }
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="details">
                        {(postingLog && postingLog.length>0) &&
                            <LeadPostLog postlog={postingLog} isPing={true}/>
                        }
                    </TabPane>
                    <TabPane tabId="postlog">
                        {ioLog &&
                            <LeadIOLog iolog={ioLog}/>
                        }
                    </TabPane>
                </TabContent>
            </div>
        </Container>
    )
}

export default Ping
