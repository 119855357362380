import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit2, Trash} from "react-feather";
import Loader from "../../../components/Loader";
import Product from "../../../components/fields/Product";

const Returnreasons = (props) => {

    const [reasons,setReasons] = useState(null)

    let history = useHistory()

    const newReason = () => {
        history.push("/settings/returnreason/item")
    }

    const editReason = (id) => {
        history.push("/settings/returnreason/item/"+id)
    }

    const deleteReason = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this return reason?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-danger',
                    onClick: () => {
                        API.delete('/admin/returnreason/'+id).then((response) => {
                            loadReasons()
                        })
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    const loadReasons = () => {
        API.get('/admin/returnreason/all').then((response) => {
            setReasons(response.data.data)
        })
    }

    useEffect(()=>{
        loadReasons()
    },[])

    return <Container fluid className="p-0">
        <Button color="primary" onClick={newReason} className="float-right mt-n1">
            <FontAwesomeIcon icon={faPlus}/> New return reason
        </Button>
        <h1 className="h3 mb-3">Return reasons</h1>
        <Row>
            <Col>
                <Card>
                    {reasons ?
                        reasons.length > 0 &&
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Product</th>
                                    <th>Date add</th>
                                    <th>Date update</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    reasons.map((reason, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><strong>{reason.title}</strong></td>
                                                <td>{reason.products &&
                                                    reason.products.map((product,key)=>{
                                                        return <Product product={product} key={key}/>
                                                    })
                                                }</td>
                                                <td>{reason.dateadd}</td>
                                                <td>{reason.dateupdate}</td>
                                                <td className="table-action">
                                                    <Edit2 onClick={() => {editReason(reason.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    <Trash onClick={() => {deleteReason(reason.id)}} className="cursor-pointer align-middle text-danger" size={18} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                        :
                        <CardBody><Loader/></CardBody>
                    }
                </Card>
            </Col>
        </Row>
    </Container>
}

export default Returnreasons
