import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";

const PhoneNumberScrubber = (props) => {
    const [phone, setPhone] = useState({phone:null,description:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setPhone({ ...phone, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/badphone/'+props.match.params.id).then((response) => {
                setPhone(response.data.data)
            })
            
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/badphone/'+props.match.params.id,phone)
                .then((response) => {
                    toastr.success('Success', 'Phone was successfully saved')
                    history.push("/settings/phonenumberscrubber")
                })
                .catch(error => {
                    setError('Duplicate phone')
                })
        }else {
            API.post('/admin/badphone',phone)
                .then((response) => {
                    toastr.success('Success', 'PHone was successfully saved')
                    history.push("/settings/phonenumberscrubber")
                })
                .catch(error => {
                    setError('Duplicate phone')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/phonenumberscrubber">Phone Number Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} bad phone number</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Phone number</Label>
                            <AvField 
                                onChange={handleChange}
                                required
                                type="text"
                                name="phone"
                                value={phone.phone}
                                placeholder="Phone number"
                                validate={{
                                    required: {value: true, errorMessage: 'Please enter a phone number'},
                                    pattern: {value: '/^[0-9]{10}$/', errorMessage: 'Please enter 10-digit phone number'},

                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <AvField
                                type="select"
                                required
                                name="description"
                                value={phone.description}
                                onChange={handleChange}
                                placeholder={<div>Type to search</div>}
                                validate= {{required: {value: true, errorMessage: 'Please select a description'},}}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'Litigator'}>Litigator</option>
                                <option value={'DNC'}>DNC</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default PhoneNumberScrubber;