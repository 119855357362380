import React from "react";
import {Badge} from 'reactstrap';

const LeadStatus = ({ status}) => {
    if (status === 'incoming') {
        return <Badge color="primary" className="mr-1 mb-1">{status}</Badge>
    }else if (status === 'error') {
        return <Badge color="danger" className="mr-1 mb-1">{status}</Badge>
    }else if (status === 'flagged') {
        return <Badge color="warning" className="mr-1 mb-1">{status}</Badge>
    }else if (status === 'matched') {
        return <Badge color="success" className="mr-1 mb-1">{status}</Badge>
    }else if (status === 'unmatched') {
        return <Badge color="secondary" className="mr-1 mb-1">{status}</Badge>
    }else if (status === 'matchednobuyer') {
        return <Badge color="success" className="mr-1 mb-1">matched no buyer</Badge>
    }else {
        return <Badge color="secondary" className="mr-1 mb-1">{status}</Badge>
    }
};

export default LeadStatus;
