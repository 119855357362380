import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const ProductMulti = ({name,value,onChange}) => {
    const [products,setProducts] = useState(null)
    const [productValues,setProductValues] = useState(null)

    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    useEffect(() => {
        API.get('/system/products').then((response) => {
            let options,i,productList = [], productValuesList = []
            if (response.data.data) {
                options = response.data.data

                for (i=0;i<options.length;i++) {
                    productList.push({value:options[i].id,label:options[i].title})
                }
            }
            setProducts(productList)
        });
    }, [])

    useEffect(() => {
        let i, productValuesList = []

        if (products !== null && value) {
            for (i=0;i<products.length;i++) {

                if (value.includes(products[i].value.toString())) {
                    productValuesList.push(products[i])
                }
            }

            if (productValuesList.length > 0) {
                setProductValues(productValuesList)
            }else {
                setProductValues(null)
            }
        }else {
            setProductValues(null)
        }
    },[products,value])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={products}
            value={productValues?.length > 0?productValues:undefined}
            onChange={(value)=>changeSelect(value)}
            isMulti
            placeholder="Product"
        />
    )

}

export default ProductMulti;
