import React,{useState,useEffect} from "react";

const ReturnStatistic = ({statistic}) => {
    if (statistic) {
        const returned = parseInt(statistic.returned);
        const sent = returned + parseInt(statistic.matched);
        const percent = parseInt(100 * returned / sent);
        return <>{percent}%</>
    }else {
        return <></>
    }
}

export default ReturnStatistic;
