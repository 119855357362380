import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import AdminSelect from "../../components/formfields/AdminSelect";
import { Edit2 } from "react-feather";
import FilterComponent from "../../components/FilterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LenderStatus from "../../components/status/LenderStatus";
import LenderTitle from "../../components/fields/LenderTitle";
import Loader from "../../components/Loader";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import SortableTable from "../../components/SortableTable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import LenderStatusSelect from "../../components/formfields/LenderStatusSelect";
import CSVDownlaod from "../../components/CsvDownload";
import usePermissions from "../../hooks/usePermissions";

const Lenders = () => {
  let history = useHistory();
  // From first day of previous month to today
  // const defaultDate = dateFormat(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), "mmm d, yyyy") + " to " + dateFormat( new Date(), "mmm d, yyyy");
  
  // From the previous 45 days to today
  const defaultDate = dateFormat(new Date().setDate(new Date().getDate() - 45), "mmm d, yyyy") + " to " + dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    // page: 1,
    // perPage: 1000000,
    date: defaultDate,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [lenders, setLenders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = React.useState(
    filtersParsed?.searchText || ""
  );

    const columns = [
        {
            name: 'ID',
            selector: row => parseInt(row.id),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.title.toLowerCase(),
            cell: (row) => (
                <LenderTitle title={row.title} id={row.id}/>
            ),
            sortable: true,
        },
        {
            name: 'Type',
            cell: row => (
                <div>
                     {row.products.map(item => item !== null ? <Product key={item.id} product={item}/> : '' )}
                </div>
            ),
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: (row) => (
                <LenderStatus status={row.status}/>
            ),
            sortable: true,
        },
        {
            name: 'Leads Today',
            selector: row => row.matchedtoday,
            sortable: true,
        },
        {
            name: 'Returns Waiting',
            selector: row => row.requested,
            cell: (row) => (
                row.requested > 0 ? <Link to={"/lender/returnrequests?date="+encodeURIComponent(filtration.date)+"&status=requested&lender="+row.id} > {row.requested} </Link> : 0
            ),
            sortable: true,
        },
        {
            name: 'Date Added',
            selector: row => row.dateadd,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <Edit2 onClick={() => {editLender(row.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />,
            sortable: false,
        },

    ];

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  const updateFilterText = (value) => {
    setFiltration({ ...filtration, searchText: value });
    setFilterText(value);

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        searchText: value,
      }).toString(),
    });
  };

  const changeNativeData = (e) => {
    setFiltration({ ...filtration, [e.target.name]: e.target.value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        [e.target.name]: e.target.value,
      }).toString(),
    });
  };

  const newLender = () => {
    history.push("/lender/form");
  };

  const editLender = (lenderId) => {
    history.push("/lender/form/" + lenderId);
  };

  const filteredItems =
    lenders &&
    lenders.length > 0 &&
    lenders.filter(
      (item) =>
        item["id"]
          .toString()
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1 ||
        item["title"].toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    useEffect(() => {
        setLoading(true)
        API.post('/admin/lender/list',filtration).then((response) => {
            let lendersList = response.data.data
            API.post('/admin/return/returnsByLender', filtration).then((res) => {
                let returnItems = res.data.data
                lendersList.forEach((element) => {
                    let item = returnItems.find(returnItem => returnItem.lender.id === element.id)
                    if(item) {
                        element['requested'] = item.requested
                    }else {
                        element['requested'] = 0
                    }
                })
                setLenders(lendersList);
                setLoading(false)
            });
        });
    }, [filtration])

  const canLenderDownload = usePermissions('download_lenderlist');

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Lenders</h3>
        </div>
        <div className="col-auto">
            {canLenderDownload &&
              <CSVDownlaod
                url="/admin/lender/lenderlist"
                filename="LenderList"
                filtration={filtration}
              />
            }
        </div>
      </div>
      <Row form className="mt-1">
        <Col md={3}>
          <FilterComponent
            placeHolder={"Search..."}
            onFilter={(e) => updateFilterText(e.target.value)}
            filterText={filterText}
          />
        </Col>
        <Col md={3}>
          <ProductMulti
            name={"product"}
            onChange={changeData}
            value={filtration?.product}
          />
        </Col>
        <Col md={3}>
          <LenderStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col md={3}>
          <AdminSelect
            name={"representative"}
            onChange={changeNativeData}
            value={filtration?.representative}
            required={false}
            placeholder={"Account representative..."}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={newLender}>
            <FontAwesomeIcon icon={faPlus} /> New lender
          </Button>
        </Col>
      </Row>
      <Row form className="mt-2">
        <Col>
          {loading && <Loader />}
          <Card>
            {!loading && lenders && lenders.length > 0 ? (
              <SortableTable
                columns={columns}
                data={filteredItems}
                pagination={false}
              />
            ) : (
              !loading && (
                <CardBody className="text-center">
                  <h5>Data not found</h5>
                </CardBody>
              )
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Lenders;
