import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
import API from "../../../utils/API";
import {Link, useHistory} from "react-router-dom";
import {toastr} from "react-redux-toastr";

const Emailscrubber = (props) => {
    const [email, setEmail] = useState({email:null,description:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value })
        setError(null)
    };

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/bademail/'+props.match.params.id).then((response) => {
                setEmail(response.data.data)
            })
            
        }
    },[])

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/bademail/'+props.match.params.id,email)
                .then((response) => {
                    toastr.success('Success', 'Email was successfully saved')
                    history.push("/settings/emailscrubber")
                })
                .catch(error => {
                    setError('Duplicate email')
                })
        }else {
            API.post('/admin/bademail',email)
                .then((response) => {
                    toastr.success('Success', 'Email was successfully saved')
                    history.push("/settings/emailscrubber")
                })
                .catch(error => {
                    setError('Duplicate email')
                })
        }
    }

    return (
        <Container fluid className="p-0">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/settings/emailscrubber">Email Scrubber</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{props.match.params.id?'Edit':'New'} email</BreadcrumbItem>
            </Breadcrumb>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvField onChange={handleChange} type="email" name="email" value={email.email} placeholder="Email address"                                 
                            validate={{
                                    required: {value: true, errorMessage: 'Please enter an email address'},
                                    email: {value: true, errorMessage: 'Please enter a valid email address'},
                                }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <AvField
                                type="select"
                                name="description"
                                value={email.description}
                                onChange={handleChange}
                                validate={{
                                    required: {value: true, errorMessage: 'Please select a description'},

                                }}
                                placeholder={<div>Type to search</div>}
                            >
                                <option value={''} hidden>Select...</option>
                                <option value={'Litigator'}>Litigator</option>
                                <option value={'Do Not Email'}>Do Not Email</option>
                            </AvField>
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default Emailscrubber;
